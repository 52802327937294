@import "./src/styles/vars"

.container
  position: relative
  padding: 6px
  margin: 15px 10px
  display: flex
  flex-direction: column
  align-items: flex-start
  box-shadow: 0 0 3px #2d89cd
  border-radius: 5px
  transition: 0.2s ease
  &:hover
    box-shadow: 0 0 10px #2d89cd
