.wrapper
  position: relative
  height: 100%
  background-color: #457d8e
  color: white

.content
  display: flex
  justify-content: center

.about
  width: 1200px
  margin-top: 20px
  padding: 0 7%

.title
  font-size: 3rem
  font-weight: 600
  line-height: 1.1

.description
  font-size: 1.5rem
