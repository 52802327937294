@import "../../../../../../styles/vars"

.container
  display: flex

  button
    z-index: 1
    color: white
    background-color: $main-color
    border-left: 1px solid $gray-color
    transition: .2s linear
    border-top-left-radius: 5px
    border-bottom-left-radius: 0 !important
    font-size: 1rem !important
    text-transform: capitalize

    &:first-child
      border-left: 0
    &:last-child
      border-top-right-radius: 5px
      border-bottom-right-radius: 0
    &:hover
      color: white
      background-color: $active-blue-color
      z-index: 0


.active
  background-color: $active-blue-color !important
