@import 'src/styles/vars'

.img
  height: 125px
  width: 125px
  box-shadow: 0 0 2px black
  padding: 6px
  cursor: pointer
  transition: 0.5s ease
  opacity: 1

.img:hover
  box-shadow: 0 0 5px $green-color

.imageWrapper
  position: relative

.loading
  .img
    opacity: 0

  .spinner
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center

