@import "../../../../../styles/vars"

.card
  display: flex
  justify-content: center
  align-items: center
  width: 39px
  height: 39px
  border: 1px solid #bababa
  border-radius: 5px
  background-color: white
  font-size: 1.3rem
  padding: 10px
  margin-right: 5px
  margin-bottom: 5px

.boxWithSymbols
  position: relative
  padding: 7px
  background-color: #ffffff
  border: $border-edit
  border-radius: 5px
  margin-bottom: 15px
  margin-top: 15px
  width: 100%

.cardContainer
  display: flex

.center
  justify-content: center !important

.droppableBox
  display: flex
  justify-content: flex-start
  flex-wrap: wrap
  &Short
    flex-wrap: nowrap
    overflow-y: auto
    min-height: 40px

.cardDragging
  background-color: #a3ceff

.disabledBox
  position: relative
  background-color: #9d9d9d

.placeholder
  width: 100%
  align-self: center
  justify-self: center
  color: $muted-color
  text-align: center

.times
  position: absolute
  opacity: 0.7
  top: 0
  left: 5px
  font-size: 2.5rem
  font-weight: 600
  color: red

