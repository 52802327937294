.container
  width: 459px
  padding-top: 30px

.lg
  max-width: 500px

.full
  min-width: 588px
  min-height: 800px
  padding-top: 0
