.container
  display: flex
  justify-content: center
  position: relative
  margin-top: 10px

.questionImage
  max-height: 306px
  height: auto
  border-radius: 8px
