@import "../../../../../../styles/general"
@import "../../../../../../styles/vars"

.container
  display: flex
  justify-content: center
  flex-direction: column

.formulaButton
  display: flex
  justify-content: center
  cursor: pointer
  border: $border-edit
  transition: 0.3s ease
  background-color: #FFFFFF
  border-radius: 8px
  p
    font-weight: 600
    margin: 0
    padding: 8px
    color: $sub-color

  &:hover
    box-shadow: $box-shadow-hover

.editWrapper
  position: relative

.correctAnswerImage
  background-color: limegreen

.answerBackground
  background-color: $default-background-color
