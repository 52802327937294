.achievement
  position: relative
  display: flex
  align-items: center
  margin: 10px auto 0
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1)
  border: 1px solid lightgrey
  padding: 10px
  width: 100%
  max-height: 150px
  border-radius: 5px
  background-color: #FFFFFF
  min-height: 70px
  &:hover .editButtons
    display: block
  img
    position: relative
    align-self: flex-start
    margin: 0 11px 0 2px
    height: auto
    width: 60px

.select
  cursor: pointer

.achievement:after
  position: absolute
  content: ""
  width: 15px
  height: 100%
  background: #ffffff
  left: 0
  top: 0
  border-radius: 15px
  z-index: 1
  transform: rotate(25deg)
  animation: slide 3s infinite ease-in-out

.text

.disabled
  background-color: #eaeaea
  cursor: default

@keyframes slide
  0%
    left: 0
    opacity: 0
  76%
    opacity: 0.5
  100%
    opacity: 0
    left: 90%

.editButtons
  display: none

.rating
  color: #00a2ff
  margin-left: 5px
  i
    margin-right: 0
