.cropperContainer
  position: relative
  width: 350px
  height: 350px

.buttons
  margin-top: 10px
  display: flex
  justify-content: center
  button
    width: 18%
    min-height: 25px !important
    margin-right: 10px !important
  p
    font-size: 1.3rem
