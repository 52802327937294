@import "src/styles/vars"

.switch
  text-align: left

  label
    cursor: pointer
    display: flex
    align-items: center

    input[type="checkbox"]
      opacity: 0
      width: 0
      height: 0
      position: absolute
      pointer-events: none

      & + .lever
        background-color: lightgrey

      &:checked + .lever::after
        left: 1.5rem
        background-color: $sub-color

      &:disabled +.lever
        cursor: default

        &::after
          background-color: #bdbdbd


.lever
  position: relative
  display: inline-block
  margin: 0 .625rem
  margin-right: 1.25rem
  vertical-align: middle
  content: ""
  background-color: #818181
  border-radius: .9375rem
  width: 2.5rem
  height: .9375rem
  -webkit-transition: background .3s ease
  transition: background .3s ease

  &::after
    position: absolute
    top: -.1875rem
    left: -.3125rem
    display: inline-block
    content: ""
    background-color: #f1f1f1
    border-radius: 1.3125rem
    -webkit-box-shadow: 0 .0625rem .1875rem .0625rem rgba(0,0,0,0.4)
    box-shadow: 0 .0625rem .1875rem .0625rem rgba(0,0,0,0.4)
    width: 1.3125rem
    height: 1.3125rem
    -webkit-transition: left .3s ease,background .3s ease,-webkit-box-shadow 1s ease
    transition: left .3s ease,background .3s ease,-webkit-box-shadow 1s ease
    transition: left .3s ease,background .3s ease,box-shadow 1s ease
    transition: left .3s ease,background .3s ease,box-shadow 1s ease,-webkit-box-shadow 1s ease

