.col
  padding: 0

.col12
  display: flex
  margin-top: 5px

.col8
  padding: 0
  height: 785px
  display: flex
  justify-content: center

.col4
  padding: 0
  height: 785px
  display: flex
  justify-content: center
