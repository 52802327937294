@import 'src/styles/vars'

.container
  display: flex
  flex-direction: column
  position: absolute
  right: 0
  top: 0
  transition: 0.3s ease
  z-index: 100
  span i
    &:hover
      transform: scale(1.1)
      color: $red-color

.editItems
  border: $border-edit
  color: #00A5D0
  font-size: 1.1em
  background-color: $transparent-white
  width: 30px
  line-height: 25px
  text-align: center
  &:first-child
    border-top-right-radius: 0

.isSolidBorder
  border: 1px solid lightgrey

.withoutTopBorder
  border-top-color: transparent

.withoutRightBorder
  border-right-color: transparent

.withoutBottomBorder
  border-bottom: none



