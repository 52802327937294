@import "../../../../../../styles/vars"

.container
  position: relative
  width: 100%
  display: flex
  justify-content: center
  flex-direction: column
  border-radius: 5px

.border
  border: $border-edit

.withoutBottomMargin
  margin-bottom: 0
