@import "src/styles/vars"

.event
  &__title
    display: flex
    align-items: center
    font-weight: 600
  &__description
    font-weight: 400
    color: $gray-color
  &__rating, &__coin
    display: flex
    align-items: center
    margin-left: 10px
    font-weight: 500
    p
      margin-left: 5px
    img
      width: 23px
      height: 23px
    i
      color: $icon-color
      cursor: default
      margin-right: 0
  &__coin
    img
      width: 21px
      height: 21px

.logo
  display: flex
  align-items: center
  &__image
    width: 70px
    height: 70px

.checkBox
  display: flex
  justify-content: center
  align-items: center

.content
  display: flex
  flex-direction: column
  justify-content: center

@media screen and (max-width: 768px)
  .event
    font-size: 1.2rem
