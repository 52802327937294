@import 'src/styles/vars'

.container
  display: flex
  justify-content: center
  background-color: $transparent-white
  border-bottom-right-radius: 5px
  border-bottom-left-radius: 5px
  i
    margin-right: 10px
  p
    padding: 0
  &_mini
    i
      margin: 0

.button
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  border-top: $border-edit
  border-right: $border-edit
  flex-grow: 1
  cursor: pointer
  color: $button-text-color
  font-size: 1rem
  font-weight: 500
  transition: text-shadow 0.2s ease
  &:last-child
    border-right: none
  &:hover
    color: $red-color
  &_mini
    border-top: none
  &_single
    border-bottom: $border-edit
