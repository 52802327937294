.DragArea {
  background: #FFF;
  //margin-top: 60px;
  //padding: 20px;

  * {
    color: initial;
  }
}

.Size {
  color: #BA001F;
  cursor: pointer;

  &Selected {
    color: #3FD200;
  }
}

.OutlineType {

  &Selected {
    color: gray;
  }
}

.ContextActions {
  padding: 4px 0;
  margin: 0;
  list-style: none;
  background: #e0e0e0;

  & > li {
    padding: 4px 8px;
    cursor: pointer;
    color: #2d2d2d;

    &:hover {
      color: #000;
    }
  }
}
