.container
  padding: 7px
  background-color: #ffffff
  border: 1px solid #a8a8a8
  border-radius: 5px
  margin-bottom: 15px
  margin-top: 15px

.card
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  min-width: 80px
  height: 40px
  border: 1px solid #00a2ff
  border-radius: 5px
  margin-bottom: 6px
  margin-right: 16px
  background-color: white
  font-size: 1.1rem
  font-weight: 600

.droppableBox
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  min-height: 45px

.cardWrapper
  margin-right: 10px
