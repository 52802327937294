@import 'src/styles/vars'

.container
  width: 100%
  display: flex
  flex-direction: column
  height: 100%
  justify-content: space-between
  min-height: 786px

