@import 'src/styles/vars'

.staticAnswers
  margin-top: 10px
  width: 45%
  text-align: center

.dynamicAnswers
  margin-top: 10px
  width: 45%
  text-align: center

.answerContainer
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 10px
  position: relative
  height: 150px
  border-radius: 5px
  background-color: #FFFFFF
  padding: 5px
  img
    max-height: 100%
    border-radius: 8px
    padding: 5px

.border
  border: $border-edit

.createContainer
  font-size: 1rem
  font-weight: bold
  border: $border-edit
  border-radius: 8px
  background-color: $transparent-white
  transition: box-shadow .2s linear
  p
    padding: 2px
  &:hover
    border-color: $green-hover
