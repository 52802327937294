@import 'src/styles/vars'

.nav
  border-bottom: 2px solid $blue-hover
  font-weight: 500
  margin-bottom: 20px

  &_sectionLeft p
    color: $sub-color

  &_sectionRight
    margin: 4px 15px

    span
      padding: 5px 15px
      cursor: pointer
      &:hover
        background-color: $main-color
        border-radius: 5px 5px 0 0


@media (max-width: 768px)
  .nav
    flex-direction: column
