.achievementWrapper
  position: fixed
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  bottom: 40px
  left: 50%
  margin-left: -200px
  border-radius: 20px
  transition: .2s ease-in
  z-index: 9000
  animation: fade 15s linear
  background-origin: content-box
  cursor: pointer

@keyframes fade
  0%, 100%
    opacity: 0
  25%, 75%
    opacity: 1

@media screen and (max-width: 768px)
  .achievementWrapper
    left: 0
    right: 0
    margin-left: 0
    font-size: 2rem
    width: 100%
    img
      width: 100px
      height: 100px
