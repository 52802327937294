@import 'src/styles/vars'

.container
  display: flex
  width: 100px
  cursor: pointer
  text-align: center
  align-self: flex-start
  color: $text-color
  &:hover
    color: $red-color

.narratorFrame
  display: flex
  align-items: center
  width: 110px
  height: 110px
  img
    width: 100%
    height: auto
  &:hover
    border-color: $green-color

.border
  border: $border-edit
