@import 'src/styles/vars'

.image
  max-width: 350px
  height: auto
  min-height: 50px

.formulaWrapper
  border: $border-edit
  margin-bottom: 10px
  cursor: pointer
