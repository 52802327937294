.site-width
  max-width: $site-width

.w-30
  width: 30%

.mw-70px
  max-width: 70px

.mw-100px
  max-width: 100px

.mh-90
  max-height: 90%

.mh-70px
  max-height: 70px
