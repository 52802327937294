@import 'src/styles/vars'

.container
  //display: flex
  //justify-content: center
  //width: 100%
  //margin-top: 45px
  padding-bottom: 16px

.createContainer
  width: 100%
  //box-shadow: 0 0 5px lightgrey
  padding: 25px
  border-radius: 8px
  label
    font-size: 1.2rem

.prompt
  text-align: left
  font-size: 0.9rem
  color: lightslategrey

//  navigation
.navigation
  margin-bottom: 0
  display: flex
  flex-direction: row
  margin-block-start: 0
  margin-block-end: 0
  margin-inline-start: 0
  margin-inline-end: 0
  padding-inline-start: 0
  padding: 0 8px 2px
  box-shadow: 0 2px 0 $main-color
  justify-content: space-between

.navigationStep
  color: black
  cursor: pointer
  padding: 8px 16px
  text-decoration: none
  display: inline-block
  font-weight: 500

  &Active
    border-radius: 8px 8px 0 0
    background-color: $main-color
    color: white
    transition: text-shadow 0.2s ease

    &:hover
      text-shadow: 0 0 4px white
      color: white

  &Disabled
    opacity: .5
    cursor: not-allowed

    &:hover
      color: black

