.container
  display: flex
  justify-content: space-between
  height: 70vh

.library
  width: 74%
  overflow-y: auto
  border: 1px solid lightgrey
  border-radius: 5px

.column
  width: 28%
  padding: 0 0 0 15px
