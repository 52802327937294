@import "src/styles/vars"

.NumericSequenceContainer
  display: grid
  grid-template-columns: 48% 48%
  grid-gap: 16px
  justify-content: center
  margin-top: 15px

.NumericSequenceItem
  position: relative
  display: flex
  justify-content: center
  align-items: center
  height: 185px
  cursor: pointer
  background-color: white
  border-radius: 5px
  padding: 10px
  img
    height: 100%
    max-height: 150px

.NumericSequenceItemIndex
  position: absolute
  width: 80px
  height: 80px
  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.8)
  background: rgba(59, 191, 2, 0.8)
  display: flex
  align-items: center
  justify-content: center
  border-radius: 100%
  font-size: 2.5rem
  font-weight: bold
  color: white

  --animate-duration: 0.25s

.NumericSequenceItemBorder
  border: $border-edit

