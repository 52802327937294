@import "../../../../../../styles/vars"

.container
  width: 100%
  button
    z-index: 1
    color: white
    background-color: $main-color
    box-shadow: none !important
    margin: 0
    padding: 10px !important
    border-left: 1px solid lightgrey
    transition: .2s linear


    font-size: 1rem !important
    text-transform: capitalize

    &:first-child
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px
    &:last-child
      border-top-right-radius: 5px
      border-bottom-right-radius: 5px

    &:hover
      color: white
      background-color: #03A6D1
      z-index: 0


.active
  background-color: #03A6D1 !important
