@import "../../../../../styles/vars"

.headerDescription
  text-align: left

.container
  display: flex
  justify-content: space-between
  min-height: 500px

.left
  display: flex
  flex-direction: column
  width: 40%
  margin-top: 10px

.right
  display: flex
  flex-direction: column
  width: 57%
  padding: 10px

.boxWithSymbols
  padding: 7px 7px 7px 10px
  background-color: #ffffff
  border-radius: 5px
  margin-bottom: 15px
  width: 100%

.modalLg
  max-width: 60%

.disabledBox
  background-color: #eeeeee

.title
  text-align: center
  font-size: 1rem
  font-weight: bold
  margin-bottom: 10px
  color: $muted-color

.dragging
  background-color: #fff6d1
  transition: .3s linear

.answerHeader
  display: flex
  justify-content: space-between
  align-items: center

.link
  text-align: right
  color: #0085ba
  cursor: pointer
  margin-bottom: 9px

.select
  margin: 15px 0

.footer
  display: flex

.helpText
  margin-top: 10px
  margin-bottom: 0

.border
  border: 1px solid lightgrey
