.header
  padding: 5px 35px
  font-size: 1rem
  font-weight: 400
  min-height: 60px

.container
  display: flex
  padding: 5px 40px 5px 33px
  height: 50px

.button
  margin: 5px 10px
  button
    height: 37px
    width: 140px
  img
    height: 35px
    width: 35px
    margin-right: 5px

.bellContainer
  display: flex
  justify-content: center
  align-items: center
  min-width: 50px

.bell
  display: flex
  justify-content: center
  align-items: center
  position: relative
  color: white
  cursor: pointer
  transition: .2s ease-in
  i
    font-size: 1.6rem
  span
    position: absolute
    min-width: 20px
    right: -10px
    top: -10px
    border-radius: 25px

.avatar
  position: relative
  img
    background-color: white
    width: 35px
    height: 35px
    border-radius: 50%
    margin-left: 10px
  span
    position: absolute
    min-width: 20px
    right: 0
    top: 4px
    border-radius: 25px

.navLink
  &:hover
    color: gold !important

.notifications
  span
    margin-left: 5px

.rating
  padding: 0 5px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50px
  font-weight: 600
  align-self: center
  cursor: pointer
  color: gold
  transition: color .2s ease
  &:hover
    color: #efb43f
  img
    width: 30px
    height: 30px
    margin-right: 5px

.coins
  padding: 0 5px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50px
  font-weight: 600
  align-self: center
  margin-right: 10px
  color: gold
  transition: color .2s ease
  cursor: pointer
  &:hover
    color: #efb43f
  img
    width: 30px
    height: 30px
    margin-right: 5px

.isActive
  color: gold !important

.brandContainer
  display: flex
  align-items: center
  margin-right: 15px
  img
    margin-top: -2px

.brandTitle
  margin-left: 10px
  margin-bottom: 0
  font-size: 1rem

.mobileHeaderContainer
  top: 0
  z-index: 9999 !important

.navItemMobile
  justify-content: flex-start !important
  font-size: 2.7rem
  margin-left: 10px
  padding-bottom: 0.5rem

.mobileHeader
  height: 60px
  display: flex
  width: 100%
  justify-content: space-between
  align-items: center

.mobileToggler
  font-size: 2rem

.mobileHeaderAuth
  flex-direction: row
  font-size: 1.3rem

.mobileHeaderRight
  display: flex
  align-items: center
  gap: 1rem
  padding-right: 1rem
  li
    margin-right: 10px !important
    &:last-child
      margin-right: 0 !important
  div
    margin-bottom: 0

.exitButton
  font-size: 1rem
  p
    color: white !important


@media screen and (max-width: 768px)
  .bell
    margin-right: 0
  .coins, .rating
    margin-right: 0
    margin-bottom: 15px
  .avatar
    img
      margin-left: 0
  .exitButton
    justify-content: flex-start !important
    font-size: 2.7rem
    margin-left: 10px
    padding-bottom: 0.5rem
    p
      color: white !important
