.contentWrapper
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column

.content
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 15px
  min-width: 80%

.image
  width: 150px
  height: auto

.descriptionBlock
  display: flex
  flex-direction: column
  padding-left: 20px
  b
    font-weight: 600

.title
  font-weight: 600
  font-size: 1.5rem

.description
  font-size: 1.2rem
