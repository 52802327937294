@import "../../../../../../styles/vars"

.container
  height: 700px
  width: 100%
  margin-bottom: 20px
  
.word
  cursor: pointer

.textBox
  padding: 20px
  background-color: #E6E6E6
  line-height: 1.5
  font-size: 1.2rem
  border-radius: 5px
  text-align: left
  color: black
  height: 100%
  overflow-y: auto

.selectedWord
  color: $red-color
