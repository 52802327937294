@import 'src/styles/vars'

.container
  width: 588px
  height: 100%
  display: flex
  justify-content: center
  border-radius: 10px
  overflow-y: auto
  min-height: 788px

.border
  border: $border-edit

.full
  width: 588px !important
