.selectedSymbol
  color: #00c400

.symbol
  cursor: pointer
  &:hover
    color: red

.text
  font-size: 1.5rem

.title
  margin-bottom: 10px
