@import './vars'
@import "./event-styles"
@import "~react-image-gallery/styles/scss/image-gallery.scss"

body
  margin: 0
  font-family: 'Open Sans', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-attachment: fixed

p
  margin-bottom: 0

*
  &::-webkit-scrollbar
    width: 10px
  &::-webkit-scrollbar-track
    background-color: #ececec
  &::-webkit-scrollbar-thumb
    background-color: #33b5e5
  scrollbar-width: thin


code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.shadow-0
  -webkit-box-shadow: none!important
  box-shadow: none!important


.shadow-1
  -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,.05)!important
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.05)!important


.shadow-2
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,.05)!important
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.05)!important


.shadow-3
  -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05)!important
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.05)!important


.shadow-4
  -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.05)!important
  box-shadow: 0 15px 30px 0 rgba(0,0,0,.05)!important


.shadow-5
  -webkit-box-shadow: 0 20px 40px 0 rgba(0,0,0,.05)!important
  box-shadow: 0 20px 40px 0 rgba(0,0,0,.05)!important

.image-gallery-slide .image-gallery-description
  bottom: 0

.image-gallery-description
  width: 400px
  margin-left: auto
  margin-right: auto
  left: 0
  right: 0
  text-align: center
  color: #212529 !important
  font-weight: 600
  background: none !important

.image-gallery-right-nav
  opacity: 0
  right: -40px

.image-gallery-left-nav
  opacity: 0
  left: -40px

.image-gallery-slide-wrapper
  transition: 2s visibility ease-in
  &:hover
    .image-gallery-right-nav
      opacity: 1
    .image-gallery-left-nav
      opacity: 1

.image-gallery-image
  margin-bottom: 40px

@media screen and (max-width: 780px)
  .image-gallery-right-nav
    opacity: 1
    right: 0
  .image-gallery-left-nav
    opacity: 1
    left: 0
