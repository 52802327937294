.controls
  height: 70px
  width: 100%
  max-width: 588px
  align-items: center
  justify-content: space-between
  margin-top: 15px
  display: none
  i
    margin-left: 5px
  button
    font-size: 1.5rem !important
  &__check
    width: 60%
    height: 100%
  &__next
    width: 30%
    height: 100%
    margin-right: 20px !important

.isVisible
  display: flex
  justify-content: center

@media screen and (max-width: 970px)
  .controls
    display: flex
    justify-content: center
