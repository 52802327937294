@import "../../../../../../../styles/vars"

.addImage
  border-radius: 4px
  text-align: center
  border: $border-edit
  padding: 8px
  cursor: pointer
  transition: .2s ease
  background-color: $transparent-white
  margin-top: 10px
  &:hover
    border-color: $green-hover
  p
    text-align: center
    margin: 0
    padding: 5px
    font-weight: 500
