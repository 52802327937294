@import "../../../../../../styles/vars"

.container
  display: flex
  justify-content: center
  width: 100%

.createContainer
  width: 100%
  margin-top: 15vh
  max-width: 500px
  box-shadow: 0 0 5px rgba(0,0,0,0.25)
  padding: 25px
  border-radius: 8px
  background-color: #ffffff
  label
    font-size: 1.2rem

.helpMessage
  text-align: left
  font-size: 0.9rem
  color: lightslategrey
  padding: 3px 0

