.container
  display: flex
  justify-content: center
  min-height: 40vh

.imageWrapper
  position: relative
  img
    width: 340px
    height: auto

.preview
  width: 100%
  margin-top: 8px

.uploadWrapper
  display: flex
  justify-content: center

.optionsWrapper
  margin-left: 50px
  display: flex
  flex-direction: column

.optionsFooter
  display: flex
  margin-top: 7%
  justify-content: space-between
  padding: 20px 0

.title
  font-size: 1.1rem
  font-weight: 600
  margin-bottom: 5px

.buttonWrapper
  display: flex
  justify-content: center
  gap: 5px
