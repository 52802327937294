@import "src/styles/vars"

.container
  vertical-align: center
  color: $title-color !important
  font-weight: 400
  padding: 15px 0 !important
  height: 50px
  margin: 0 25px 0 5px
  font-size: 1rem
  transition: none !important
  &:hover
    border-bottom: 3px solid $blue-hover
  &:first-child
    margin-left: 0

.active
  border-bottom: 3px solid $blue-hover

@media screen and (max-width: 768px)
  .container
    margin-right: 0
