.wrapper
  padding: 0
  height: 100vh
  width: 1008px
  margin: 0 auto
  min-width: 600px

body
  background-color: #FFFFFF

@media screen and (max-width: 1008px)
  .wrapper
    width: 100%
