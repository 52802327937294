@import "./src/styles/vars"

p
  margin: 0
  padding: 0

.content
  position: relative
  height: 95%

.container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  border: 1px solid lightgray
  border-radius: 5px
  border-top-left-radius: 0
  border-top-right-radius: 0
  overflow-y: auto
  background-color: $background-column-color
  p
    align-self: start

.selectContainer
  padding: 20px 0
  max-height: 500px
  width: 90%

.propertyContainer
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  padding: 10px
  text-align: center
  margin: 10px 0 0

.property
  display: flex
  justify-content: space-between
  border-radius: 4px
  padding: 5px
  width: 100%

.imagesContainer
  height: 100%

.narratorContainer
  overflow-y: auto
  height: 100%

.backgroundWrapper
  height: 100%
  max-height: 670px
  overflow-y: auto
  overflow-x: hidden
  margin-top: 10px

.propertyTitle
  font-size: 1.1rem
  font-weight: 600
  margin-bottom: 5px
  padding-left: 5px

.switch
  display: flex
  width: 100%
  padding: 10px
  justify-content: space-between
  align-items: center

.fontSizer
  padding-right: 10%
  padding-left: 5%

.helperBox
  margin-top: auto
  word-break: break-word
  width: 90%
  p
    display: flex
    align-items: flex-start
    text-align: left
    line-height: 1.3
    i
      padding-top: 3px
      padding-right: 4px
      font-size: 2rem
