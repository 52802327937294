.coins, .rating, .avatar
  font-size: 1.7rem
  img
    width: 50px
    height: 50px

.bell
  i
    font-size: 2.6rem
  span
    position: absolute
    min-width: 32px
    right: -17px
    top: -8px
    border-radius: 50%

.avatar
  span
    position: absolute
    min-width: 32px
    border-radius: 50%
    top: 5px

.badge
  font-size: 1.4rem
