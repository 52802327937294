@import "src/styles/vars"

.container
  width: 100%
  padding: 20px
  margin: 20px 0
  word-break: break-word
  text-align: left
  background-color: #FFFFFF
  border-radius: 8px
  font-size: 20px
  font-weight: 400
  line-height: 2

.popupContainer
  box-shadow: 0 0 5px #898989

.skipButton
  padding: 0 10px !important
  width: auto
  height: 32px !important
  min-width: 80px
  font-size: 1.2rem !important
  margin: 0 5px 0 0 !important

.variantsWrapper
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap
  padding: 5px

.variant
  display: flex
  justify-content: center
  align-items: center
  border: 1px solid #898989
  font-size: 16px
  font-weight: 600
  min-width: 40px
  min-height: 40px
  margin-right: 10px
  padding: 5px 10px
  border-radius: 4px
  color: #434242
  cursor: pointer
  transition: .3s ease
  background-color: #FAFAFA
  margin-bottom: 5px
  margin-top: 5px
  &:last-child
    margin-right: 0

  &:hover
    box-shadow: $main-shadow
