@import 'src/styles/vars'

.imageWrapper
  position: relative

.img
  width: 100%
  padding: 0
  cursor: pointer
  height: 100%
  min-height: 100px
  transition: .2s ease-in-out
  &:hover + .checkCircle
    display: block
    color: $green-hover

.checkCircle
  display: none
  position: absolute
  top: 20%
  left: 23%
  font-size: 3rem
  pointer-events: none
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff

.imgSelected
  display: block
  color: $green-hover


