.boxWithWords
  padding: 10px 7px 7px 7px
  background-color: #ffffff
  border-radius: 5px
  margin-bottom: 15px
  margin-top: 15px
  font-weight: 400
  p
    text-align: center
    height: 40px

.dragging
  background-color: #add8e6 !important
  transition: .3s linear

.box
  display: flex
  justify-content: center
  align-items: center
  background-color: #FFFFFF
  border: 3px dashed lightblue
  border-radius: 8px
  padding: 0 3px 3px 5px
  max-height: 40px
  margin: 7px 7px 10px

.skipsContainer
  background-color: #FFFFFF
  padding: 0 20px 20px 20px
  font-size: 1.1rem
  line-height: 3
  font-weight: 400
  border-radius: 8px
  margin-top: 10px
  p
    text-align: center
    height: 50px
    font-size: 1.1rem

.times
  color: red
  border-left: 3px dashed lightblue
  padding: 6px 2px 4px 8px
  margin-left: 5px

.skips
  display: flex
  flex-wrap: wrap

.emptyBox
  min-width: 50px
