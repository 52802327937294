@import 'src/styles/vars'

.container
  width: 453px
  min-height: 50px
  position: relative
  background-color: white
  display: flex
  justify-content: center
  align-items: center
  border-radius: 5px
  padding: 10px
  p
    margin: 0
    font-weight: 500

.containerText
  word-wrap: break-word
  white-space: pre-wrap
  font-size: 1.1rem
  max-width: 320px
  text-align: left
  width: 100%
  margin: 0 10px
  min-width: 250px
  line-height: 1.3



