@import '../../../../../styles/vars'

.addContent
  display: flex
  justify-content: center
  align-items: center
  border: $border-edit
  border-radius: 8px
  height: 100px
  margin: 20px 0
  background-color: $transparent-white
  cursor: pointer
  transition: .2s linear
  &:hover
    border-color: $green-hover

.textPreview
  position: relative
  width: 100%
  word-break: break-word
  margin: 20px 0
  padding: 20px
  border: $border-edit
  border-radius: 5px
  background-color: $transparent-white


