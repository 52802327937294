@import '../../../../../../styles/vars'

.skip
  background-color: #00a2ff
  display: inline-block
  margin: 0 2px
  width: 20px
  height: 18px
  cursor: pointer
  &:hover
    background-color: #1181a0

.card
  display: flex
  justify-content: center
  align-items: center
  min-width: 40px
  height: 40px
  border: 1px solid #bababa
  border-radius: 5px
  margin-bottom: 6px
  margin-right: 6px
  background-color: white
  font-size: 1.1rem
  font-weight: 600
  padding: 5px

.skipContainer
  display: flex
  justify-content: center
  align-items: center
  margin-top: 10px
  margin-bottom: 30px

.text
  font-size: 1.5rem

.buttonText
  font-size: 1.1rem

.title
  margin-bottom: 10px

.missText
  margin-top: 10px
