.events
  padding: 0
  width: 100%
  height: 60vh
  &__title
    padding: 10px 10px
    font-size: 1rem
    font-weight: 600
  &__content
    height: calc(100% - 80px)
    overflow-y: auto
    padding: 0 20px 0
    margin-top: 20px
