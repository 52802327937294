.imagesLibrary
  padding: 15px 8px
  height: 100%

.addLinkWrapper
  display: flex
  align-items: center
  justify-content: center

.addLink
  display: flex
  height: 125px
  width: 125px
  box-shadow: 0 0 2px black
  transition: box-shadow .2s ease
  padding: 2px
  cursor: pointer
  font-size: 60px
  align-items: center
  justify-content: center

.images
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-template-rows: 125px
  row-gap: 20px
  justify-items: center

.imagesFullWidth
  grid-template-columns: repeat(5, 1fr)

.toLibraryLink
  text-align: left
  margin-top: 8px
  padding-bottom: 16px

.actions
  margin-top: 8px

.textHelper
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  height: 100%
  i
    margin-bottom: 10px
    font-size: 4rem
  p
    text-align: center
    font-size: 1.4rem
