@import "../../../../../styles/vars"

.container
  display: flex
  justify-content: space-between
  height: 500px
  span
    cursor: pointer
    margin-bottom: 20px

.selected
  color: #BA001F

.skip
  cursor: default !important
  border: 2px dotted #22b2ea
  background-color: #FFF
  margin: 0 5px 0 0
  padding: 5px
  border-radius: 5px
  i
    border-left: 2px dotted $main-color
    color: red
    padding: 5px 5px 5px 10px
    margin-right: 0

.selectedSkip
  border: 2px dotted #00c400

.left
  width: 68%
  height: 500px

.textBox
  background-color: #E6E6E6
  line-height: 40px
  border-radius: 5px
  padding: 15px
  text-align: left
  color: black
  height: 500px

.right
  display: flex
  flex-direction: column
  width: 30%

.saveButton
  margin-top: 10px
  display: flex

.newAnswersContainer
  border: 2px dotted #45B7D6
  border-radius: 5px
  padding: 15px
  height: 100%

.answersContainer
  display: flex
  flex-wrap: wrap
  margin-top: 10px

.answersContainerItem
  display: flex
  justify-content: center
  align-items: center
  margin-right: 5px
  margin-bottom: 10px
  border: 1px solid #898989
  color: #434242
  font-size: 0.9rem
  font-weight: 600
  border-radius: 3px
  height: auto
  background-color: #FAFAFA
  word-break: break-word
  padding: 2px 5px
  i
    color: red
    margin-left: 5px
    margin-right: 5px

.addNewAnswerContainer
  display: flex
  align-items: center
  justify-content: space-between
  height: auto
  input
    width: 100%
    min-height: 14px
  button
    margin: 0
    padding: 0 15px !important
    font-size: 1rem !important
    box-shadow: none
    &:hover
      box-shadow: none

.addItem
  min-height: 35px !important
  margin-left: 5px !important
