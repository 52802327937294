@import 'src/styles/vars'

.container
  position: relative
  margin-top: 15px
  display: flex
  flex-direction: column
  justify-content: center
  width: 100%

.selectType
  align-self: center
  width: 100%
  border: $border-edit
  border-radius: 4px
  p
    text-align: center
    margin: 0
    padding: 5px
    font-weight: 500

.selectText
  background-color: $transparent-white
