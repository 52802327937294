@import "src/styles/vars"

.wrapper
  position: relative
  width: 100%
  display: flex
  justify-content: center
  margin-top: 10px

.isConstructor
  background-color: white
  padding-bottom: 10px
  margin-top: 0

.player
  width: 90%

audio::-webkit-media-controls-panel
  background-color: #add8e6

audio::-webkit-media-controls-time-remaining-display
  display: none

audio::-webkit-media-controls-current-time-display
  display: none
