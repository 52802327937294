@import '../../../../../../styles/vars'

.container
  position: relative
  border-radius: 5px
  margin-bottom: 15px
  cursor: pointer
  background-color: $transparent-white
  transition: .2s ease

.withBorder
  border: $border-edit
  border-radius: 5px

.correctAnswer
  div
    -webkit-box-shadow: inset 0 0 0 4px $green-hover
    -moz-box-shadow: inset 0 0 0 4px $green-hover
    box-shadow: inset 0 0 0 4px $green-hover
    border-radius: 5px
    background-color: rgb(176, 236, 147)

.playSelect
  div
    -webkit-box-shadow: inset 0 0 0 4px $main-color
    -moz-box-shadow: inset 0 0 0 4px $main-color
    box-shadow: inset 0 0 0 8px $main-color
    border-radius: 5px
    background-color: rgb(218, 227, 255)
    transition: .2s ease

.answerContainer
  position: relative

.twoColumnsMode
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  .container
    width: 45%
  .answerContainer
    width: 45%

.containerAddAnswerText
  display: flex
  align-items: center
  justify-content: center
  position: relative
  border-radius: 5px
  padding: 5px
  color: $sub-color
  font-weight: 500
  font-size: 16px
  cursor: pointer
  background-color: $transparent-white
  transition: box-shadow 0.2s ease
  height: 50px
  border: $border-edit
  &:hover
    color: $red-color
    border-color: $green-color
