@import "src/styles/vars"

.container
  display: flex
  justify-content: flex-end
  border-bottom: 1px solid lightgrey
  padding: 0 35px
  box-shadow: none
  min-height: 50px !important

.brand
  width: 60px
  height: 60px
  background-color: red

.toggler
  height: 50px
.isActive
  color: gold !important

.navLink
  &:hover
    color: gold !important

.exitButton
  font-weight: 400
  cursor: pointer
  font-size: 1rem

.socials
  display: flex
  align-items: center
  justify-content: center
  margin-right: 20px
  img
    height: 28px
    width: 28px
    transition: .2s ease
    cursor: pointer
