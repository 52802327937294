.wrapper
  width: 100%
  display: flex
  justify-content: center
  margin-top: 30px

.container
  width: 90%
  display: flex
  flex-direction: column
  justify-content: center
  p
    font-weight: 400
  h5
    font-weight: 600
    font-size: 18px
