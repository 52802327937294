.container
  display: flex
  justify-content: center
  flex-direction: column

.pagination
  display: flex
  justify-content: center
  margin-top: 15px

.search
  display: flex
  margin-bottom: 15px
  input
    max-width: 350px
    margin-right: 15px

.content
  width: 100%
