@import "../../../../../../styles/vars"

.container
  position: relative
  width: 100%
  padding: 20px
  margin: 20px 0
  word-break: break-word
  text-align: left
  background-color: #FFFFFF
  border-radius: 8px
  line-height: 2.2
  white-space: normal
  font-size: 20px

.skipButton
  padding: 2px 2px !important
  width: 20px
  font-size: 14px !important
  margin: 0 1px 0 1px !important

.activeButton
  background-color: #45B7D6  !important
  p
    font-size: 18px

.unactiveButton
  background-color: #a3a3a3 !important

.variantsWrapper
  display: flex
  justify-content: center
  align-items: center
  padding: 5px

.variant
  display: flex
  justify-content: center
  align-items: center
  border: 1px solid #898989
  font-size: 16px
  font-weight: 600
  min-width: 40px
  min-height: 40px
  margin-right: 10px
  padding: 3px
  border-radius: 4px
  color: #434242
  cursor: pointer
  transition: .3s ease
  background-color: #FAFAFA
  &:last-child
    margin-right: 0
  &:hover
    box-shadow: 0 0 5px #0e98ff

.border
  border: $border-edit

.popupContainer
  box-shadow: $main-shadow
