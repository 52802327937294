@import './vars'

.link
  line-height: 1.2
  cursor: pointer
  color: $text-color
  font-size: 1rem
  &:hover
    color: $red-color

.skipBox
  background-color: #7ae6ff
  display: inline-block
  vertical-align: middle
  margin: 0 2px
  width: 20px
  height: 20px
  cursor: pointer

.noselect
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

button
  margin: 0 !important
  overflow-wrap: normal !important

.btn-group>.btn
  text-transform: none
  font-size: 1rem !important
  padding: 10px 20px
  box-shadow: none
  border-radius: 5px
  &.btn-info.active
    box-shadow: $active-shadow
    &:first-child
      box-shadow: $active-shadow-first
    &:last-child
      box-shadow: $active-shadow-last

.btn
  box-shadow: $main-shadow
  font-size: 0.9rem !important
  border-radius: 0.2rem
  text-transform: none

.btn-success
  background-color: $green-color !important
  &:hover
    background-color: $green-hover !important
  &.disabled
    background-color: #8ab677 !important
    opacity: 1 !important

.btn-info
  background-color: $blue-color !important
  &:hover
    background-color: $blue-hover

.info
  background-color: $blue-color !important

.btn-default
  background-color: white
  color: black

.close
  padding: 5px !important

.custom-range
  max-width: 75%

// upload image

.fileContainer
  box-shadow: none

// modals
.modal-header
  padding: 10px 20px 10px 30px
  background-color: $blue-color
  p
    color: white
    font-size: 1.6rem
    font-weight: 500
  span
    font-size: 30px

.modal-body
  padding: 30px
  textarea
    width: 100%
    border-radius: 5px
    border: 1px solid lightgrey
    padding: 10px
    resize: none
    &:focus
      outline: none !important
      border: 1px solid $green-hover
      box-shadow: $main-shadow

.modal-footer
  display: flex
  justify-content: flex-start
  padding-left: 30px
  padding-right: 30px

.progress-bar.bg-successful
  background-color: $green-color

// input

.form-control
  min-height: 45px
  color: hsl(0,0%,50%)
  &:focus
    border: 1px solid $green-hover
    box-shadow: 0 0 0.2rem $green-hover

input[type=radio]
  width: auto
  height: 15px
  min-height: auto
  border: none !important
  box-shadow: none !important

button
  &:focus
    outline: none

.modal-lg
  max-width: 900px !important

.title
  font-size: 1.1rem
  font-weight: 600

.subtitle
  font-size: 1rem
  font-weight: 600

::placeholder
  color: #ACACAC

.sizeS
  width: 120px
  height: 120px

.sizeM
  width: 180px
  height: 180px

.sizeXL
  width: 200px
  height: 200px

.SIZE_S
  width: 75px
  height: 75px

.SIZE_M
  width: 95px
  height: 95px

.SIZE_L
  width: 120px
  height: 120px

.SIZE_XL
  width: 150px
  height: 150px

.row
  margin-left: 0
  margin-right: 0

.progress
  height: 5px

.gold
  color: gold

.silver
  color: silver

.bronze
  color: rosybrown

.pagination
  padding-bottom: 25px

.nav-item
  display: flex
  align-items: center
  justify-content: center

.modal-backdrop
  height: 100%
  width: 100%

.badge
  padding: 5px

.pagination.pg-blue .page-item.active .page-link
  background-color: #45B7D6
  &:hover
    background-color: #00A6D2

.swiper-pagination-bullet
  background-color: white !important

.header
  background-color: #424242
  width: 100%

.header-container
  margin: 0 auto
  max-width: 1008px
  background-color: #424242
  & > .navbar
    box-shadow: none !important

.bg-primary
  background-color: #45B7D6 !important

.modal-fluid
  width: 90% !important

#vk_groups2
  margin-top: 20px

.infinite-scroll-component__outerdiv
  width: 100%
