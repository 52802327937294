@import './src/styles/vars'

.container
  display: flex
  justify-content: center
  align-items: center
  border: $border-edit
  border-radius: 8px
  height: 100px
  margin: 20px 0
  background-color: $transparent-white
  cursor: pointer
  transition: .2s linear
  &:hover
    border-color: $green-hover

