@import '../../../../../styles/vars'

.container
  height: 100%
  min-height: 650px

.header
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-bottom: 20px
  max-width: 70%

.headerTitle
  font-size: 18px
  font-weight: bold

.headerDescription
  text-align: left

.textBox
  background-color: #E6E6E6
  border-radius: 5px
  padding: 15px
  text-align: left
  color: black
