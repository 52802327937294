@import '../../../../../../styles/vars'

.container
  height: 100%
  display: flex
  justify-content: space-between

.description
  display: flex
  flex-direction: column
  align-items: flex-start
  text-align: left

.header
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-bottom: 20px

.leftWrapper
  display: flex
  flex-direction: column
  align-items: flex-start
  width: 70%

.rightWrapper
  display: flex
  flex-direction: column
  width: 28%

.descriptionWrapper
  width: 100%

.descriptionContainer
  border-radius: 5px
  padding: 10px
  border: 2px dotted $main-color
  margin-bottom: 10px

.headerTitle
  font-size: 18px
  font-weight: bold

.headerDescription
  text-align: left

.title
  font-weight: 500
  font-size: 1rem

.textInfo
  margin-top: 10px
