@import "./src/styles/vars"

.container
  line-height: 1.3
  img
    width: 50px
    height: auto
    margin-bottom: 5px

.title
  font-size: 1.33rem
  font-weight: 600
  margin-bottom: 20px

.cardContainer
  position: relative
  font-size: 1.2rem
  color: #2d2d2d
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  height: 150px
  border: $border-edit
  border-radius: 8px
  margin: 15px 0
  padding: 20px
  cursor: pointer
  &:hover
    color: $green-hover
    border:  3px dashed #3FD200

.info
  position: absolute
  right: 0
  top: 0
  border-left: 3px dashed lightgray
  border-bottom: 3px dashed lightgray
  padding: 5px
  color: #45B7D6
  transition: .2s ease
  i
    margin-right: 0
  &:hover
    color: $green-hover
    border-color: $green-hover

.modalContent
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  background-color: white
  color: #494949
  padding: 20px
  min-height: 427px
  height: 60%
  width: 500px
  border-radius: 20px

.helpLink
  font-size: 1.1rem
  display: flex
  justify-content: center
  align-items: center

.logoWrapper
  display: flex
  justify-content: center
  margin-bottom: 20px
  img
    width: 100px
    height: 100px
