@import 'src/styles/vars'

.content
  display: flex
  justify-content: center
  align-items: center
  border-radius: 8px
  padding: 12px
  font-weight: 500
  min-height: 69px
  height: 100%
  width: 100%
  background-color: white
  text-align: center
  p
    word-break: break-word

.border
  border: $border-edit
