@import "./../../styles/player-vars"

.stepper
  position: absolute
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  flex-wrap: wrap
  width: 70vw
  font-weight: 400
  color: #b9b9b9
  &__step
    display: flex
    justify-content: center
    align-items: center
    height: 50px
    width: 50px
    margin: 5px
    cursor: pointer
    border: 1px solid lightgray
    border-radius: 50%
    font-size: 1.5rem
  &__current
    color: white
    background-color: #00c0ff !important
    font-weight: 600
  &__correct
    color: white
    background-color: #00b000
  &__uncorrect
    color: white
    background-color: #ff0000
  &__exit
    color: white
    background-color: #a65e2e
    font-size: 1.5rem
    i
      margin-left: 5px

.steps
  max-width: 250px
  width: 80%
  display: flex
  justify-content: center
  flex-wrap: wrap

.header
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 20px 10px
  text-align: center
  height: 200px
  img
    max-width: 75px
  &__subject
    font-size: 1.5rem
    color: #434242
    font-weight: 600
    margin-top: 5px
  &__theme
    margin-bottom: 10px
    font-size: 1.4rem
    font-weight: 200
    color: #434242
