$site-width: 1008px

// colors
$main-color: #45B7D6
$sub-color: #00A6D2
$shadow-color: #0580f2
$icon-color: #00A6D2
$inactive-icon-color: #d9dada
$muted-color: #404040
$text-color: #3B91D0

$red-color: #D00
$green-color: #3FD200
$blue-color: #45B7D6
$gray-color: #898989
$light-gray: #eaeaea
$title-color: #434242
$light-gray-color: #f8f8f8

// hovers
$green-hover: #3bbf02
$blue-hover: #00A6D2
$box-shadow-hover: 0 0 15px $shadow-color

//active colors
$active-blue-color: #14799e

// buttons
$button-text-color: #0666ab

// borders
$border-edit-color: #b7b7b7
$border-edit: 3px dashed $border-edit-color

// background-colors
$background-column-color: #F9F9F9

// backgrounds
$default-background-color: #FFFFFF
$transparent-white: rgba(255, 255, 255, 0.75)

// shadows
$main-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1)
$hover-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1)

$active-shadow: -3px 0 12px -2px rgba(0, 0, 0, 0.2), 3px 0 10px -2px rgba(0, 0, 0, 0.2)
$active-shadow-first: 3px 0 12px -2px rgba(0, 0, 0, 0.2)
$active-shadow-last: -3px 0 12px -2px rgba(0, 0, 0, 0.2)

// content padding
$content-padding: 40px 40px
