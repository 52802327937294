@import 'src/styles/vars'

.answerContainer
  position: relative

.containerAddAnswerText
  display: flex
  align-items: center
  justify-content: center
  position: relative
  border: $border-edit
  border-radius: 8px
  padding: 5px
  color: $text-color
  font-weight: 500
  font-size: 1rem
  cursor: pointer
  background-color: $transparent-white
  transition: box-shadow 0.2s ease
  height: 50px
  i
    margin-right: 10px
  &:hover
    border-color: $green-color
