@import '../../../../../styles/vars'

.container
  position: relative
  width: 100%
  border-radius: 8px
  padding: 20px
  margin: 20px 0
  word-break: break-word
  text-align: left
  background-color: #FFFFFF

.border
  border: $border-edit

.skip
  display: inline-block
  vertical-align: middle
  margin: 0 7px
  background-color: #FFFFFF
  border: 2px dotted $main-color
  border-radius: 5px
  width: 90px
  height: 20px
