@import 'src/styles/vars'

.container
  display: flex
  flex-wrap: wrap
  max-height: 665px
  justify-content: space-around
  overflow: auto

.imageWrapper
  display: flex
  justify-content: center
  align-items: center
  padding: 10px
  background-color: white
  position: relative
  margin: 5px 5px 10px
  transition: .3s ease
  cursor: pointer
  border-radius: 10px
  &.correctAnswerImage
    box-shadow: inset 0 0 0 4px $green-hover
    border-radius: 10px
  img
    border-radius: 5px
    width: 100%

.playSelected
  &.correctAnswerImage
    box-shadow: inset 0 0 0 8px $main-color
    border-radius: 10px

.border
  border: $border-edit
  border-radius: 5px

.answerContainer
  margin: 5px 5px 10px
  position: relative
  border: $border-edit
  border-radius: 5px

.addAnswer
  display: flex
  justify-content: center
  align-items: center
  background-color: white
  cursor: pointer
  color: $sub-color
  transition: 0.2s ease
  text-align: center
  padding: 10px
  &:hover
    border-color: $green-color

.rounded
  border-radius: 100%
  display: flex
  justify-content: center
  align-items: center
  img
    height: 85%
    width: 85%
    border-radius: 100%
  &.correctAnswerImage
    box-shadow: inset 0 0 0 4px $green-hover
    border-radius: 100%

.disableDrag
  pointer-events: none
