@import "./general"
@import "../assets/symbols/style.css"
@import "./spacing"
@import "./sizing"

.App
  margin: 0 auto
  height: 100vh
  color: #444444
  min-width: 420px

html
  height: 100%
  font-size: 15px

i
  cursor: pointer
  margin-right: 5px
  
.hidden
  display: none

.cursor-pointer
  cursor: pointer

.cursor-default
  cursor: default

.framed-icons > :not(:last-child)
  margin-right: .4rem

.d-flex-centered
  display: flex
  justify-content: center
  align-items: center

.d-flex-centered-x
  display: flex
  align-items: center

.d-iflex-aic
  display: inline-flex
  align-items: center

.col-faic-3, .col-faic-4
  display: flex
  align-items: center

.col-faic-3
  flex: 0 0 25%
  max-width: 25%

.col-faic-4
  flex: 0 0 33.33%
  max-width: 33.33%

h1
  font-size: 2rem

h2
  font-size: 1.5rem
  font-weight: 600

